<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>Benchmark Comparison Combined</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" small @click="getData" :loading="loading">
					Get Data
				</v-btn>
			</template>
			<v-row>
				<v-col cols="12" class="pb-0">
					<h3 class="">SELECT TOPICS</h3>
				</v-col>
				<v-col cols="12">
					<v-select v-model="report_params.selected_topics" :items="topics" item-text="name" item-value="id" label="Topics" multiple clearable></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="pb-0">
					<h3 class="">DATA SET 1</h3>
				</v-col>
				<v-col>
					<v-select v-model="report_params.data_set_1.selected_industries" :items="industries" item-text="name" item-value="id" label="Industries" multiple clearable></v-select>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.data_set_1.start_date" label="Start date" clearable></v-text-field>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.data_set_1.end_date" label="End date" clearable></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="pb-0">
					<h3>DATA SET 2</h3>
				</v-col>
				<v-col>
					<v-select v-model="report_params.data_set_2.selected_industries" :items="industries" item-text="name" item-value="id" label="Industries" multiple clearable></v-select>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.data_set_2.start_date" label="Start date" clearable></v-text-field>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.data_set_2.end_date" label="End date" clearable></v-text-field>
				</v-col>
			</v-row>
		</ti-card>
		<ti-card>
			<v-row>
				<v-col cols="12">
					<h3>DATA SET 1 RESULTS (SAMPLES: {{ report_data.data_set_1.sample_count }})</h3>
				</v-col>
				<v-col cols="12" lg="4">
					<v-data-table :headers="headers" :items="report_data.data_set_1.table_data" item-key="id">
						<template v-slot:item.avg_score="{ item }">
							{{ Math.round(item.avg_score) }}
						</template>
						<template v-slot:footer.prepend>
							<v-row>
								<v-col cols="auto">
									<ti-export-to-csv :json-data="report_data.data_set_1.table_data" filename="Benchmark Results"/>
								</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" lg="8" v-if="report_data.data_set_1.radar_data.labels.length > 0">
					<vue-apex-charts type="radar" :options="chartOptions(report_data.data_set_1.radar_data.labels)" :series="report_data.data_set_1.radar_data.series"></vue-apex-charts>
				</v-col>
			</v-row>
		</ti-card>
		<ti-card>
			<v-row>
				<v-col cols="12">
					<h3>DATA SET 2 RESULTS (SAMPLES: {{ report_data.data_set_2.sample_count }})</h3>
				</v-col>
				<v-col cols="12" lg="4">
					<v-data-table :headers="headers" :items="report_data.data_set_2.table_data" item-key="id">
						<template v-slot:item.avg_score="{ item }">
							{{ Math.round(item.avg_score) }}
						</template>
						<template v-slot:footer.prepend>
							<v-row>
								<v-col cols="auto">
									<ti-export-to-csv :json-data="report_data.data_set_2.table_data" filename="Benchmark Results"/>
								</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" lg="8" v-if="report_data.data_set_2.radar_data.labels.length > 0">
					<vue-apex-charts type="radar" :options="chartOptions(report_data.data_set_2.radar_data.labels)" :series="report_data.data_set_2.radar_data.series"></vue-apex-charts>
				</v-col>
			</v-row>
		</ti-card>
		<ti-card>
			<v-row class="justify-center">
				<v-col cols="12">
					<h3>COMBINED (SAMPLES: {{ report_data.data_set_1.sample_count + report_data.data_set_2.sample_count }})</h3>
				</v-col>
				<v-col cols="12" lg="10" xl="8" v-if="report_data.data_set_combined.radar_data.labels.length > 0">
					<vue-apex-charts type="radar" :options="chartOptions(report_data.data_set_combined.radar_data.labels)" :series="report_data.data_set_combined.radar_data.series"></vue-apex-charts>
				</v-col>
			</v-row>
		</ti-card>
	</v-container>
</template>

<script>
import {call, get} from "vuex-pathify";
import VueApexCharts from 'vue-apexcharts'

export default {
	name: "BenchmarkComparisonCombined",
	computed: {
		industries: get('user_industries/user_industries'),
		topics: get('topics/forBenchmark'),
	},
	components: {
		VueApexCharts,
	},
	beforeMount() {
		this.getTopics()
		this.getIndustries()
	},
	methods: {
		getTopics: call('topics/getTopics'),
		getIndustries: call('user_industries/getUserIndustries'),
		getData() {
			this.loading = true;
			this.$api.reports.benchmarkComparisonCombined({report_params: this.report_params})
				.then(response => {
					this.report_data = response.data
				})
				.catch(error => console.log(error))
				.finally(() => this.loading = false)
		},
	},
	data: () => ({
		loading: false,
		report_params: {
			selected_topics: [],
			data_set_1: {
				selected_industries: [],
				start_date: null,
				end_date: null,
			},
			data_set_2: {
				selected_industries: [],
				start_date: null,
				end_date: null,
			},
		},
		report_data: {
			data_set_1: {
				sample_count: 0,
				table_data: [],
				radar_data: {
					labels: [],
					series: []
				},
			},
			data_set_2: {
				sample_count: 0,
				table_data: [],
				radar_data: {
					labels: [],
					series: []
				},
			},
			data_set_combined: {
				radar_data: {
					labels: [],
					series: []
				},
			},
		},
		headers: [
			{text: "Topic", value: "topic_name"},
			{text: "Average Score", value: "avg_score", align: "right"},
		],
		chartOptions: (labels) => ({
			chart: {
				foreColor: '#000',
				type: 'radar',
				width: '100%',
				dropShadow: {
					enabled: true,
					blur: 1,
					left: 1,
					top: 1
				},
				toolbar: {
					show: false,
				}
			},
			title: {
				text: '',
				align: 'center'
			},
			stroke: {
				width: 2
			},
			fill: {
				opacity: 0.4
			},
			markers: {
				size: 3
			},
			xaxis: {
				categories: labels,
			},
			yaxis: {
				min: 0,
				max: 100,
			},
			dataLabels: {
				enabled: false
			},
		}),
	}),
}
</script>